import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root',
})
export class WalletService {
    constructor(private readonly apiService: ApiService) {}

    getIban() {
        return this.apiService.get<{ iban: string }>('/api/iban');
    }

    changeIban(iban: string) {
        return this.apiService.put('/api/iban', { iban });
    }
}
