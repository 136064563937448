import { ModalController, NavController } from '@ionic/angular/standalone';
import { _ } from '@ngx-translate/core';
import { Company } from './company';
import { Document } from './media';
import { Address, CompanyAddresses, User } from './user';

export class TodoListItem {
    name: string;
    done: boolean;
    incomplete: () => boolean; // true if incomplete, false if complete
    complete?: (navCtrl: NavController, modalCtrl: ModalController) => void; // function to call to complete the task
}

export class TodoList {
    name: string;
    description: string;
    items: TodoListItem[];

    compute() {
        this.items.forEach((i) => (i.done = !i.incomplete()));
    }

    get done() {
        return this.items.every((i) => i.done);
    }
}

export class ProTodoList extends TodoList {
    constructor(
        private readonly user: User,
        private readonly company: Company,
        private readonly documents: Document[],
        private readonly iban: string,
        private readonly addresses: CompanyAddresses,
    ) {
        super();
        this.name = _('Pro todo list');
        this.description = _('Pro todo list description');
        this.items = [
            {
                name: _('todo.pro.personal-info'),
                done: false,
                incomplete: () => {
                    return !this.user.firstname || !this.user.email || !this.user.phone || !this.user.lastname || !this.user.birthdate;
                },
                complete: (navCtrl) => {
                    navCtrl.navigateForward('/profile/personal-info');
                },
            },
            {
                name: _('todo.pro.logo'),
                done: false,
                incomplete: () => {
                    return !this.company.picture;
                },
                complete: (navCtrl, modalCtrl) => {
                    navCtrl.navigateForward('/profile/company');
                },
            },
            {
                name: _('todo.pro.company-info'),
                done: false,
                incomplete: () => {
                    const activity = this.company.activity;
                    if (activity === 'real_estate' && (!this.company.authorizationNumber || !this.company.registrationNumber)) {
                        return false;
                    }
                    if (activity === 'car_dealership' && !this.company.registrationNumber) {
                        return false;
                    }

                    return !this.company.name || !this.company.activity;
                },
                complete: (navCtrl) => {
                    navCtrl.navigateForward('/profile/company');
                },
            },
            {
                name: _('todo.pro.company-address'),
                done: false,
                incomplete: () => {
                    return !this.addresses.shipping || !this.addresses.billing || !this.addresses.return;
                },
                complete: (navCtrl) => {
                    navCtrl.navigateForward('/profile/addresses');
                },
            },
            {
                name: _('todo.pro.documents'),
                done: false,
                incomplete: () => {
                    const requiredDocuments = ['signature_authorization', 'tax_registration', 'identity_proof'];

                    if (this.company.activity === 'real_estate') {
                        requiredDocuments.push('real_estate_license');
                        requiredDocuments.push('real_estate_authorization');
                    }
                    if (this.company.activity === 'car_dealership') {
                        requiredDocuments.push('car_dealership_authorization');
                    }

                    return requiredDocuments.some((d) => !this.documents.find((doc) => doc.type === d));
                },
                complete: (navCtrl, modalCtrl) => {
                    navCtrl.navigateForward('/profile/company');
                },
            },
            {
                name: _('todo.pro.iban'),
                done: false,
                incomplete: () => {
                    return !this.iban;
                },
                complete: (navCtrl, modalCtrl) => {
                    navCtrl.navigateForward('/profile/wallet');
                },
            },
        ];
    }
}

export class UserTodoList extends TodoList {
    constructor(
        private readonly user: User,
        private readonly iban: string,
        private readonly addresses: Address[],
    ) {
        super();
        this.name = _('User todo list');
        this.description = _('User todo list description');
        this.items = [
            {
                name: _('todo.user.personal-info'),
                done: false,
                incomplete: () => {
                    return !this.user.firstname || !this.user.email || !this.user.phone || !this.user.lastname || !this.user.birthdate;
                },
                complete: (navCtrl) => {
                    navCtrl.navigateForward('/profile/personal-info');
                },
            },
            {
                name: _('todo.user.address'),
                done: false,
                incomplete: () => {
                    return this.addresses.length === 0;
                },
                complete: (navCtrl) => {
                    navCtrl.navigateForward('/profile/addresses');
                },
            },
            // {
            //     name: _('todo.pro.documents'),
            //     done: false,
            //     incomplete: () => {
            //         const requiredDocuments = ['signature_authorization', 'tax_registration', 'identity_proof'];
            //
            //         if (this.company.activity === 'real_estate') {
            //             requiredDocuments.push('real_estate_license');
            //             requiredDocuments.push('real_estate_authorization');
            //         }
            //         if (this.company.activity === 'car_dealership') {
            //             requiredDocuments.push('car_dealership_authorization');
            //         }
            //
            //         return requiredDocuments.some(d => !this.documents.find(doc => doc.type === d));
            //     },
            //     complete: (navCtrl, modalCtrl) => {
            //         navCtrl.navigateForward('/profile/company');
            //     }
            // },
            {
                name: _('todo.user.iban'),
                done: false,
                incomplete: () => {
                    return !this.iban;
                },
                complete: (navCtrl, modalCtrl) => {
                    navCtrl.navigateForward('/profile/wallet');
                },
            },
        ];
    }
}

export class NewProTodoList extends TodoList {
    constructor(private readonly user: User) {
        super();
        this.name = _('Create a new pro account');
        this.description = _('Create a new pro account description');
        this.items = [
            {
                name: _('todo.new-pro.create-company'),
                done: false,
                incomplete: () => {
                    return !this.user.company;
                },
                complete: (navCtrl) => {
                    navCtrl.navigateForward('/profile');
                },
            },
        ];
    }
}
