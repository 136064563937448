<ng-container *ngIf="address; else noAddress">
    <ng-container *ngIf="address.firstname || address.lastname"> {{ address.firstname }} {{ address.lastname }}<br /> </ng-container>
    <ng-container *ngIf="address.nameFormatted">{{ address.nameFormatted }}<br /></ng-container>
    {{ address.streetFormatted }}<br />
    {{ address.cityFormatted }}<br />
    {{ address.region }} {{ address.country | country }}
    <ng-container *ngIf="address.mobilePhone">
        <br /><br />
        <a href="tel:{{ address.mobilePhone }}">{{ address.mobilePhone }}</a>
    </ng-container>
</ng-container>
<ng-template #noAddress>
    <span class="text-muted">
        {{ "No address" | translate }}
    </span>
</ng-template>
