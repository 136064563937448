import { Injectable } from '@angular/core';
import { filter, first, firstValueFrom } from 'rxjs';
import { NewProTodoList, ProTodoList, TodoList, UserTodoList } from '../models/todo-list';
import { User } from '../models/user';
import { AddressService } from './address.service';
import { AuthService } from './auth.service';
import { CompanyService } from './company.service';
import { MediaService } from './media.service';
import { WalletService } from './wallet.service';

@Injectable({
    providedIn: 'root',
})
export class TodoListService {
    constructor(
        private readonly authService: AuthService,
        private readonly companyService: CompanyService,
        private readonly mediaService: MediaService,
        private readonly walletService: WalletService,
        private readonly addressService: AddressService,
    ) {}

    async createProTodoList(): Promise<ProTodoList> {
        return new Promise<ProTodoList>(async (resolve, reject) => {
            this.authService.userLoggedIn
                .pipe(
                    filter((user) => !!user),
                    first(),
                )
                .subscribe({
                    next: async (user) => {
                        const [company, documents, ibanResult, addresses] = await Promise.all([
                            firstValueFrom(this.companyService.get(user.company)),
                            firstValueFrom(this.mediaService.getDocuments(user.company)),
                            firstValueFrom(this.walletService.getIban()),
                            firstValueFrom(this.companyService.getAddresses(user.company)),
                        ]);
                        resolve(new ProTodoList(user, company, documents, ibanResult.iban, addresses));
                    },
                });
        });
    }

    async createNewProAccountTodoList() {
        return new Promise<NewProTodoList>(async (resolve, reject) => {
            this.authService.userLoggedIn
                .pipe(
                    filter((user) => !!user),
                    first(),
                )
                .subscribe({
                    next: async (user) => {
                        resolve(new NewProTodoList(user));
                    },
                });
        });
    }

    createUserTodoList() {
        return new Promise<UserTodoList>(async (resolve, reject) => {
            this.authService.userLoggedIn
                .pipe(
                    filter((user) => !!user),
                    first(),
                )
                .subscribe({
                    next: async (user) => {
                        const [ibanResult, addresses] = await Promise.all([
                            firstValueFrom(this.walletService.getIban()),
                            firstValueFrom(this.addressService.getAddresses(user.id)),
                        ]);
                        resolve(new UserTodoList(user, ibanResult.iban, addresses));
                    },
                });
        });
    }

    async create(user: User): Promise<TodoList> {
        return new Promise<TodoList>(async (resolve, reject) => {
            // If user is pro, load the pro todolist
            if (user.pro) {
                return resolve(await this.createProTodoList());
            }
            if (user.needProAccount) {
                return resolve(await this.createNewProAccountTodoList());
            }
            return resolve(this.createUserTodoList());
        }).then((todoList) => {
            todoList.compute();
            return todoList;
        });
    }
}
