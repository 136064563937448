<div class="todolist-container" *ngIf="todoList">
    <h3>{{ todoList.name | translate }}</h3>
    <p class="text-muted">
        {{ todoList.description | translate }}
    </p>
    <ng-container *ngFor="let item of todoList.items">
        <div
            class="d-flex justify-content-between align-items-center mb-2 gap-3 bg-light rounded p-2 clickable"
            (click)="item.complete(navCtrl, modalCtrl)"
        >
            <div>
                <i class="bi bi-check-circle-fill text-success" *ngIf="item.done"></i>
                <i class="bi bi-circle text-muted" *ngIf="!item.done"></i>
            </div>
            <div>
                <span class="fw-bold">{{ item.name | translate }}</span>
            </div>
            <div class="ms-auto clickable"><i class="bi bi-chevron-right fs-4"></i></div>
        </div>
    </ng-container>
</div>
