import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular/standalone';
import { TranslatePipe } from '@ngx-translate/core';
import { TodoList } from '../../models/todo-list';

@Component({
    selector: 'app-todo-list',
    templateUrl: './todo-list.component.html',
    styleUrls: ['./todo-list.component.scss'],
    imports: [CommonModule, TranslatePipe, CommonModule],
})
export class TodoListComponent implements OnInit {
    @Input() todoList: TodoList;

    constructor(
        public readonly navCtrl: NavController,
        public readonly modalCtrl: ModalController,
    ) {}

    ngOnInit() {}
}
