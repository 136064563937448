import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { CountryPipe } from 'src/app/pipes/country.pipe';
import { Address } from '../../models/user';

@Component({
    selector: 'app-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss'],
    imports: [CommonModule, CommonModule, TranslatePipe, CountryPipe],
})
export class AddressComponent {
    @Input() address: Address;

    constructor() {}
}
