import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslatePipe, _ } from '@ngx-translate/core';
import { default as moment } from 'moment';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';
import { AlertUtils } from 'src/app/utils/alert.utils';
import { LoadingUtils } from 'src/app/utils/loading.utils';
import { TodoList } from '../../models/todo-list';
import { User } from '../../models/user';
import { LoaderComponent } from '../loader/loader.component';

@Component({
    selector: 'app-validation-status',
    templateUrl: './validation-status.component.html',
    imports: [CommonModule, TranslatePipe, CommonModule, LoaderComponent],
})
export class ValidationStatusComponent {
    @Input() user: User;
    @Input() todoList: TodoList;
    rejectionReason?: string;

    constructor(
        private userService: UserService,
        private toastService: ToastService,
    ) {}

    ngOnInit() {
        // If status is rejected, get the reason
        if (this.user.status === 'rejected') {
            this.userService.getMyStatusValidation().subscribe({
                next: (statuses) => {
                    // We should find closest status
                    statuses.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt), 'minutes') * -1);
                    this.rejectionReason = statuses.find((s) => s.status === 'rejected')?.rejectionReason;
                },
            });
        }
    }

    askForAccountValidation() {
        AlertUtils.askConfirmation(_('Are you sure you want to ask for account validation now?')).then((confirm) => {
            if (confirm) {
                LoadingUtils.show();
                this.userService.askForAccountValidation().subscribe({
                    next: () => {
                        this.toastService.success(_('Your request has been sent'));
                        this.user.status = 'pending';
                        LoadingUtils.hide();
                    },
                    error: (err) => {
                        LoadingUtils.hide();
                        console.error(err);
                    },
                });
            }
        });
    }
}
