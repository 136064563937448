import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { SizeGuide } from '../models/size-guide';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root',
})
export class SizeGuideService {
    constructor(private readonly apiService: ApiService) {}

    list(): Observable<SizeGuide[]> {
        return this.apiService
            .get<SizeGuide[]>('/api/size-guides')
            .pipe(map((sizeGuides: any[]) => sizeGuides.map((sizeGuide: any) => new SizeGuide(sizeGuide))));
    }

    get(id: number): Observable<SizeGuide> {
        return this.apiService.get<SizeGuide>(`/api/size-guides/${id}`).pipe(map((sizeGuide: any) => new SizeGuide(sizeGuide)));
    }

    create(data: { guide: any; name: string }): Observable<SizeGuide> {
        return this.apiService.post<SizeGuide>('/api/size-guides', data).pipe(map((sizeGuide: any) => new SizeGuide(sizeGuide)));
    }

    update(id: number, data: { guide: any; name: string }): Observable<SizeGuide> {
        return this.apiService.put<SizeGuide>(`/api/size-guides/${id}`, data).pipe(map((sizeGuide: any) => new SizeGuide(sizeGuide)));
    }

    delete(id: number): Observable<any> {
        return this.apiService.delete(`/api/size-guides/${id}`);
    }
}
