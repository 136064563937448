<ng-container *ngIf="user.hasRequestedProValidation">
    <div class="alert border border-primary bg-light p-3 rounded-2 fw-bold">
        <div class="d-flex justify-content-between align-items-center gap-2">
            {{ "Your request has been sent" | translate }}
            <i class="bi bi-check-circle-fill text-primary"></i>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="user.hasDeclinedProValidation">
    <div class="alert border border-danger bg-light p-3 rounded-2 fw-bold">
        <div class="d-flex justify-content-between align-items-center gap-2">
            {{ "Your request has been declined" | translate }}
            <i class="bi bi-x-circle-fill text-danger"></i>
        </div>
        <p class="mt-3 text-muted" *ngIf="rejectionReason; else noRejectionLoading">
            {{ rejectionReason }}
        </p>
        <ng-template #noRejectionLoading>
            <p class="text-muted text-center mt-2">
                <app-loader size="sm"></app-loader>
            </p>
        </ng-template>
    </div>
</ng-container>
<ng-container *ngIf="user.hasValidatedProValidation">
    <div class="alert border border-success bg-light p-3 rounded-2 fw-bold">
        <div class="d-flex justify-content-between align-items-center gap-2">
            {{ "Your account is validated" | translate }}
            <i class="bi bi-check-circle-fill text-success"></i>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="user.needProValidation || user.hasDeclinedProValidation">
    <div class="d-flex flex-column justify-content-center align-items-center gap-2 mt-3 p-3 border border-primary rounded-2">
        <h3 class="text-center">{{ "Status validations" | translate }}</h3>
        <p class="text-center">{{ "Please validate your account to create offers on Avrazya" | translate }}</p>
        <button type="button" class="btn btn-primary btn-block" (click)="askForAccountValidation()" [disabled]="!todoList.done">
            {{ "Ask for account validation" | translate }}
        </button>
    </div>
</ng-container>
