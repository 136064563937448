import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input } from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    FormsModule,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { IonContent, IonModal } from '@ionic/angular/standalone';
import { _, TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-deci-helper',
    templateUrl: './deci-helper.component.html',
    styleUrls: ['./deci-helper.component.scss'],
    imports: [CommonModule, CommonModule, TranslateModule, ReactiveFormsModule, FormsModule, IonModal, IonContent],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DeciHelperComponent),
            multi: true,
        },
    ],
})
export class DeciHelperComponent implements ControlValueAccessor {
    @Input() placeholder: string = _('Deci');
    @Input() isInvalid: boolean = false;
    value: number = 0;
    isModalOpen = false;
    form = new FormGroup({
        width: new FormControl(0, [Validators.required, Validators.min(1)]),
        height: new FormControl(0, [Validators.required, Validators.min(1)]),
        depth: new FormControl(0, [Validators.required, Validators.min(1)]),
    });

    onInputChange(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        this.value = parseFloat(value) || 0;
        this.onChange(this.value);
        this.onTouched();
    }

    private onChange: any = () => {};
    private onTouched: any = () => {};

    writeValue(value: number): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setModalOpen(isOpen: boolean) {
        this.isModalOpen = isOpen;
    }

    submit() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }
        // Calculate deci: (width * height * depth) / 3000
        const deci = (this.form.value.width * this.form.value.height * this.form.value.depth) / 3000;
        this.value = parseFloat(deci.toFixed(2));
        this.onChange?.(this.value);
        this.onTouched?.();
        this.setModalOpen(false);
    }
}
