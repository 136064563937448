<div class="deci-helper-container">
    <div class="input-group">
        <input
            type="number"
            class="form-control"
            [value]="value"
            (input)="onInputChange($event)"
            [placeholder]="placeholder | translate"
            [class.is-invalid]="isInvalid"
        />
        <button class="btn btn-outline-secondary" type="button" (click)="setModalOpen(true)">
            <i class="bi bi-calculator"></i>
        </button>
    </div>

    <ion-modal [isOpen]="isModalOpen">
        <ng-template>
            <ion-content>
                <form class="p-4" [formGroup]="form" (submit)="submit()">
                    <div class="d-flex justify-content-between align-items-center">
                        <h3>{{ "Deci Calculator" | translate }}</h3>
                        <span class="bi bi-x fs-3 clickable" (click)="setModalOpen(false)"></span>
                    </div>
                    <div class="row mt-3 g-3">
                        <div class="col-12 col-md-4">
                            <div class="mb-3">
                                <label for="width" class="form-label">{{ "Width" | translate }}</label>
                                <input
                                    type="number"
                                    class="form-control"
                                    id="width"
                                    formControlName="width"
                                    [min]="1"
                                    [class.is-invalid]="form.get('width').invalid && form.get('width').touched"
                                />
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="mb-3">
                                <label for="height" class="form-label">{{ "Height" | translate }}</label>
                                <input
                                    type="number"
                                    class="form-control"
                                    id="height"
                                    formControlName="height"
                                    [min]="1"
                                    [class.is-invalid]="form.get('height').invalid && form.get('height').touched"
                                />
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="mb-3">
                                <label for="depth" class="form-label">{{ "Depth" | translate }}</label>
                                <input
                                    type="number"
                                    class="form-control"
                                    id="depth"
                                    formControlName="depth"
                                    [min]="1"
                                    [class.is-invalid]="form.get('depth').invalid && form.get('depth').touched"
                                />
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-primary w-100" (click)="submit()">{{ "Calculate Deci" | translate }}</button>
                </form>
            </ion-content>
        </ng-template>
    </ion-modal>
</div>
